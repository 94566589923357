<!--
 * @Description:泊位全景 订单详情 orderDetails
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-12-28 16:41:19
 * @LastEditors: houjinduo
-->
<template>
  <div class='body'>
    <van-nav-bar title="进场详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-row class="infoBox">
        <van-row class="infoTitle">
          <img :src="require('@/assets/homePage/orderIcon.png')"
               alt="">
          <span class="wenzi">订单详情</span>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">订单号</van-col>
          <van-col style="color: #333333">{{orderList.orderSequence}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">车牌号</van-col>
          <van-col class="details"
                   style="display: block;word-break: keep-all">{{orderList.plateNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">在停地</van-col>
          <van-col class="details">{{orderList.parkName}}</van-col>
        </van-row>
        <van-row class="infoLine"
                 v-if="orderList.parkSpaceNumber">
          <van-col class="name">泊位号</van-col>
          <van-col class="details">{{orderList.parkSpaceNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">开始时间</van-col>
          <van-col class="details">{{orderList.entranceTime}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">停车时长</van-col>
          <van-col class="details">{{orderList.parkDurationFormart}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">车辆欠费</van-col>
          <van-col class="details"
                   style="color:red">{{ Number(Number(orderList.arrearsMoney)/100).toFixed(2) }} 元
            <van-col v-if="Number(orderList.arrearsMoney)!==0">
              <van-button class="lineButton"
                          @click="handleClickPress(orderList)">追缴</van-button>
            </van-col>
          </van-col>
        </van-row>
        <van-row class="infoLine"
                 v-if="orderList.lastPaymentTime">
          <van-col class="name">最后缴费时间</van-col>
          <van-col class="details">{{orderList.lastPaymentTime}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col style="width: 100%;color: #909090;font-weight:400 ;">进场图片</van-col>
          <img v-if="entranceImg"
               :src="entranceImg"
               @click="handleClickPic"
               alt="">
        </van-row>
      </van-row>
      <van-row class="lineBottomButton">
        <van-row class="lineLeftButton"
                 @click="handleClickPrint(orderList)">打印凭条</van-row>
        <van-row class="lineMiddleButton"
                 @click="leaveClick(orderList)">确认离场</van-row>
        <van-row class="lineRightButton"
                 @click="handleClickAlter(orderList)">修改车辆</van-row>
      </van-row>
    </van-row>
    <!-- 弹窗 -->
    <div>
      <van-popup v-model="popisShow"
                 @close="popClose"
                 get-container="#app"
                 class="popLeave">
        <div class="popTitle">离场确认</div>
        <div class="popPlate">{{leavePlate}}</div>
        <div class="popTip">是否已经离场？</div>
        <van-uploader class="carBG"
                      v-model="fileList"
                      :after-read="afterRead"
                      :before-read="beforeRead"
                      @delete="delImg"
                      max-count="1">
          <div style="width:100%;height:100%">
            <van-image :src="require('@/assets/signIn/carimg.png')"></van-image>
          </div>
        </van-uploader>
        <div class="but-box">
          <span class="but-confirm"
                @click="leaveConfirm">确认离场</span>
          <span class="but-cancel"
                @click="popisShow=false">取消操作</span>
        </div>
      </van-popup>
    </div>
    <van-overlay :show="loadingShow"
                 @click="loadingShow = false">
      <div class="wrapper"
           @click.stop>
        <div class="block" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import parkStopTime from '@/utils/parkStopTime'
import Compressor from 'compressorjs'
import { ImagePreview, Toast } from 'vant'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      orderList: {}, // 订单详情列表
      entranceImg: '', // 入场取证
      currentTime: this.$fmtDate(new Date(), 'yyyy-MM-dd  hh:mm:ss'),
      popisShow: false, // 离场弹出框
      leavePlate: '', // 点击离场的车牌
      // 离场表单
      leaveForm: {
        evidenceImgUrl: ''
      },
      fileList: [], // 图片列表
      arrearsMoney: '0元',
      loadingShow: false // 遮罩层
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.orderList = JSON.parse(this.$route.query.orderDetails)
    this.orderList.parkDurationFormart = parkStopTime(this.currentTime, this.orderList.entranceTime)
    if (this.orderList.numberPlateColorCode) {
      this.queryArrearsMoney()
    } else {
      this.orderList.arrearsMoney = 0
    }
    this.queryInImg()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () {
  }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 点击追缴
    handleClickPress (orderList) {
      this.$router.push({
        name: 'lackOrderPay',
        query: {
          plateNumber: orderList.plateNumber,
          plateColor: orderList.plateColor,
          numberPlateColorCode: orderList.numberPlateColorCode
        }
      })
    },
    // 获取欠费
    queryArrearsMoney () {
      const info = {
        numberPlateColorCode: this.orderList.numberPlateColorCode,
        plateNumber: this.orderList.plateNumber
      }
      this.$register.queryArrears(info).then(res => {
        this.$set(this.orderList, 'arrearsMoney', res.resultEntity.arrearsMoney)
      })
    },
    // 点击打印
    handleClickPrint (item) {
      this.loadingShow = true
      Toast.loading({
        message: '正在请求接口...',
        forbidClick: true,
        duration: 0
      })
      const info = {
        orderSequence: item.orderSequence
      }
      this.$parkingOrder.charge(info).then(res => {
        this.orderList = res.resultEntity
        this.orderList.parkDurationFormart = parkStopTime(this.currentTime, this.orderList.entranceTime)

        const that = this
        const info = {
          numberPlateColorCode: res.resultEntity.numberPlateColorCode,
          plateNumber: res.resultEntity.plateNumber
        }
        // 查询车辆欠费金额
        that.$register.queryArrears(info).then(res => {
          Toast.clear()
          that.loadingShow = false
          that.arrearsMoney = Number(Number(res.resultEntity.arrearsMoney) / 100).toFixed(2) + '元'
          that.orderList.arrearsMoney = res.resultEntity.arrearsMoney
          const tips =
            '1、请按照划线停车位依次顺向停放，计费结束以车辆离场为准，请及时缴纳停车费，以免对您的出行、征信造成不良的影响。\n' +
            '2、仅限公共资源占用费、不含车辆及贵重物品保管费、离车前关闭好车辆门窗，贵重物品随身携带，避免发生财产安全损失。\n' +
            '3、收费依据：重庆市物价局（渝价【2014】175号文件执行）。\n' +
            '4、管理单位：中交建筑集团重庆建设有限公司。\n' +
            '请使用微信或支付宝扫码支付，支付完成后可以在公众号渝悦停里开取电子发票\n' +
            '客服热线：4001101699\n'
          window.android.printTicket2(
            '渝北区路侧停车登记小票',
            item.parkName,
            item.parkSpaceNumber,
            item.plateNumber,
            localStorage.getItem('userName'),
            item.entranceTime,
            that.arrearsMoney,
            'https://officialaccount.cqybxm.com/#/?page=alipayCode&orderSequence=' + item.orderSequence,
            tips,
            '做文明渝北人，请勿随地丢弃小票'
          )
        })
      })
      // 'https://cqpark-test.datatachina.com/official/#/?page=alipayCode&orderSequence=' + item.orderSequence,
      // 'https://officialaccount.cqybxm.com/#/?page=alipayCode&orderSequence=' + item.orderSequence,
    },
    // 点击放大图片
    handleClickPic () {
      const list = []
      list.push(this.entranceImg)
      ImagePreview(list)
    },
    // 获取进场图片
    queryInImg () {
      const info = {
        orderSequence: JSON.parse(this.$route.query.orderDetails).orderSequence
      }
      this.$queryDict.getEvidenceByOrderSequence(info).then(res => {
        this.entranceImg = this.$imgBaseUrl + res.resultEntity[0].evidencePic
      })
    },
    // 修改车牌
    handleClickAlter (item) {
      this.$router.push({
        name: 'alterPlateNumber',
        query: {
          alterPlateNumberList: item,
          parkTypeCode: this.$route.query.parkTypeCode,
          entranceImg: this.entranceImg
        }
      })
    },
    // 确认离场按钮
    leaveClick (berthage) {
      this.leavePlate = berthage.plateNumber
      this.leaveForm.orderSequence = berthage.orderSequence
      this.leaveForm.evidenceImgUrl = ''
      // this.fileList = []
      this.popisShow = true
    },
    // 离场确认按钮
    leaveConfirm () {
      if (!this.leaveForm.evidenceImgUrl) {
        Toast('请先上传离场图片')
        return
      }
      this.$outCharge.confirmLeave(this.leaveForm).then(res => {
        Toast('离场成功')
        this.popisShow = false
        this.$router.go(-1)
      })
    },
    // 离场弹出层关闭
    popClose () {
      this.leaveForm.evidenceImgUrl = ''
      this.fileList = []
    },
    // 文件读取前
    beforeRead (file) {
      return true
    },
    // 文件读取完成
    afterRead (file) {
      file.status = 'uploading'
      file.message = '上传中...'
      // 压缩图片
      const self = this
      /* eslint-disable no-new */
      new Compressor(file.file, {
        quality: 0.2,
        success (result) {
          // 将 Blob 对象转换成 File 对象
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$register.upFile(formData).then(res => {
            file.status = 'done'
            self.leaveForm.evidenceImgUrl = res.resultEntity
          }).catch(err => {
            console.log('图片上失败', err)
            file.status = 'failed'
            file.message = '上传失败'
          })
        },
        error (err) {
          file.status = 'failed'
          file.message = '图片压缩失败'
          console.log('压缩失败', err)
        }
      })
    },
    // 图片删除
    delImg () {
      this.leaveForm.evidenceImgUrl = ''
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    .infoBox {
      margin: 12px 3.2%;
      background-color: #ffffff;
      width: 93.6%;
      border-radius: 5px;
      padding-top: 12px;
      .infoTitle {
        margin: 7px 0 10px 10px;
        font-size: 15px;
        color: #333333;
        font-weight: 500;
        font-family: PingFang-SC;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
        .wenzi {
          margin-left: 10px;
        }
      }
      .infoLine {
        width: 100%;
        height: 38px;
        line-height: 38px;
        font-size: 0.9375rem;
        .name {
          display: block;
          width: 30%;
          font-weight: 400;
          color: #909090;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .details {
          display: block;
          width: 70%;
          position: relative;
          color: #333333;
          .lineButton {
            width: 66px;
            height: 33px;
            background: #19a9fc;
            font-family: PingFang SC;
            font-size: 0.875rem;
            font-weight: 400;
            color: #ffffff;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }

        img {
          width: 100%;
          background: #ffffff;
        }
      }
    }
  }
  .lineBottomButton {
    position: fixed;
    bottom: 0;
    height: 50px;
    background-color: #fff;
    width: 100%;
    padding-left: 2.5%;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .lineLeftButton {
      width: 25%;
      height: 32px;
      background: #ffffff;
      border: 1px solid #069f47;
      opacity: 1;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 0px;
      color: #069f47;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .lineMiddleButton {
      width: 25%;
      height: 32px;
      background: #ffffff;
      border: 1px solid #0089ff;
      opacity: 1;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 0px;
      color: #0089ff;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .lineRightButton {
      width: 25%;
      height: 32px;
      background: #ffffff;
      border: 1px solid #feb200;
      opacity: 1;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 0px;
      color: #feb200;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.popLeave {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  border-radius: 5px;
  color: #ffffff;
  user-select: none;
  .popTitle {
    height: 35px;
    width: 100%;
    text-align: center;
    line-height: 35px;
    text-align: center;
    background: #19a9fc;
  }
  .popPlate {
    height: 40px;
    width: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: #000000;
    font-weight: 600;
  }
  .popTip {
    color: #333333;
  }
  .carBG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 90%;
    height: 220px;
    margin: 10px auto;
    background: #e7eaee;
    border-radius: 5px;
    overflow: hidden;
    .van-image {
      width: 150px;
      height: 100px;
      margin: 80px 0;
    }
  }
  .but-box {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #acacac;
    cursor: pointer;
    .but-confirm {
      display: inline-block;
      width: 49%;
      color: #5dbdfc;
      border-right: 1px solid #f6f7f7;
      &:active {
        color: #7fb9e0;
      }
    }
    .but-cancel {
      display: inline-block;
      width: 50%;
      &:active {
        color: #dbdbdb;
      }
    }
  }
}
/deep/.van-uploader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__preview {
  margin: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__preview-image {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/deep/.van-uploader__preview-delete {
  width: 20px;
  height: 20px;
}
/deep/.van-uploader__preview-delete-icon {
  top: 0;
  right: 0;
}
</style>
